
  import { defineComponent, onMounted, onUnmounted } from "vue";
  import { useStore } from "vuex";
  import { Actions } from "../../../store/enums/StoreEnums";
  import { getIllustrationsPath } from "../../../core/helpers/assets";
  import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
  import { reactive } from "vue";
  import { ref } from "vue";
  import { DateTimeUtils } from "@/latipay/utils/DateTimeUtils";
  import { reinitializeComponents } from "@/core/plugins/keenthemes";
  import { EnumsConstant } from "@/store/enums/constants";
  import { getMerchantSettlementsRequest } from "@/latipay/apis/services/MerchantSettlementsService";
  import { getMerchantSettlementRecordsRequest } from "@/latipay/apis/services/MerchantSettlementRecordsService";


  export default defineComponent({
    name: "merchantSettlementRecord",
    components: {},
    setup() {
      const store = useStore();

      const type = ref<string | null>(null);
      const currency = ref<string | null>(null);
      const accountType = ref<string | null>(null);
      const accountName = ref<string | null>(null);
      const accountNumber = ref<string | null>(null);
      const sort = ref<string | null>(null);
      const pageNumber = ref(1);
      const pageSize = ref(20);

      const loading = ref(false);
      const countries = EnumsConstant.CountryCode;
      const currencies = EnumsConstant.CurrencyCode;

      const formInline = reactive({
        type: "",
        currency: "",
        accountType: "",
        accountName: "",
        accountNumber: ""

      });

      const {
        merchantSettlementRecordsRef,
        totalRef,
        pageNumberRef,
        pageSizeRef,
        getMerchantSettlementRecords
      } = getMerchantSettlementRecordsRequest();


      const getData = async () => {
        loading.value = true;

        // await updateQueries();
        await getMerchantSettlementRecords(
          formInline.type,
          formInline.currency,
          formInline.accountType,
          formInline.accountName,
          formInline.accountNumber,

          sort.value,
          pageNumber.value,
          pageSize.value
        );
        reinitializeComponents();

        loading.value = false;

        console.log(merchantSettlementRecordsRef);
      };

      const onSubmit = () => {
        console.log("submit!");
      };


      onMounted(async () => {
        // setCurrentPageTitle("Users");
        await getData();
      });

      const updatePageSize = async (val: number) => {
        console.log(`${val} items per page`);
        pageSize.value = val;
        await getData();
      };
      const updatePageNumber = async (val: number) => {
        console.log(`current page: ${val}`);
        pageNumber.value = val;
        await getData();
      };

      return {
        getIllustrationsPath,
        formInline,
        onSubmit,
        countries,

        getData,
        loading,
        updatePageSize,
        updatePageNumber,
        merchantSettlementRecordsRef,
        totalRef,
        pageNumberRef,
        pageSizeRef,
        type,
        currency,
        accountType,
        accountNumber,
        accountName,
        currencies,
        DateTimeUtils

      };
    },

    methods: {
      async resetFormFields() {

        (this.$refs["searchForm"] as any).resetFields();

        await this.getData();

      },
      async onSubmitSearchForm() {
        await this.getData();
      }

    }
  });
